<template>
  <v-container>
    <v-row>
      <template v-if="checkMobile">
        <!-- Mobile View -->
        <v-col cols="12">
          <v-card
            fluid
            class="pa-3 shape-border d-flex flex-column justify-space-between"
          >
            <div>
              <h2 class="text-h2-pre deep-blue--text pb-3 text-left">
                My Profile
              </h2>
              <div class="d-flex flex-column">
                <div class="d-flex align-start mb-2">
                  <span class="text-data-header">Name:</span>
                  <span class="text-data-body ml-2">
                    {{ data?.firstname }} {{ data?.lastname }}
                  </span>
                </div>
                <div class="d-flex align-start mb-2">
                  <span class="text-data-header">E-mail:</span>
                  <span class="text-data-body ml-2">{{ data?.email }}</span>
                </div>
                <div class="d-flex align-start mb-2">
                  <span class="text-data-header">Phone:</span>
                  <span class="text-data-body ml-2">{{ data?.phone }}</span>
                </div>
                <div class="d-flex align-start mb-2">
                  <span class="text-data-header">Address:</span>
                  <div class="d-flex flex-column ml-2">
                    <span class="text-data-body">{{ data?.address1 }}</span>
                    <span class="text-data-body">{{ data?.address2 }}</span>
                    <span class="text-data-body">
                      {{ data?.city }}, {{ data?.state }} {{ data?.zip }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex flex-column align-start mt-1">
              <span class="text-data-body">
                To update this information, please message your care team.
              </span>
            </div>

            <v-btn
              class="d-flex mt-3"
              @click="openModal()"
              elevation="0"
              style="width: 100%; border-radius: 40px"
              color="primary"
              rounded
            >
              Update Insurance
            </v-btn>
            <UpdateInsuranceDialog
              ref="updateInsurance"
              :dialog="dialog"
              :submitting="submitting"
              @close="dialog = !dialog"
            />
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-card-title class="d-flex align-center justify-space-between">
              <h4>Recent login activities</h4>
            </v-card-title>
            <v-card-text>
              <v-data-table
                :loading="loading"
                :headers="headersSessions"
                :items="sessions"
                :items-per-page="5"
                class="elevation-0"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip top color="error">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon
                          small
                          @click="logoutSpecific(item.id)"
                          color="error"
                        >
                          mdi-logout
                        </v-icon>
                      </span>
                    </template>
                    <span>Logout</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-title class="d-flex align-center justify-space-between">
              <v-btn
                class="d-flex"
                elevation="0"
                color="error"
                @click="logoutAll"
              >
                Logout All
              </v-btn>
              <v-btn
                class="d-flex ml-2"
                elevation="0"
                color="error"
                @click="logoutAllNotCurrent"
              >
                Logout All Not Current
              </v-btn>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" v-if="isShowPasswordReset">
          <PasswordReset @reset="reloadPasswordReset" />
        </v-col>
      </template>

      <template v-else>
        <!-- Web View -->
        <v-col cols="6">
          <v-card
            :width="550"
            :height="400"
            fluid
            class="pa-5 shape-border d-flex flex-column justify-space-between"
          >
            <div>
              <h2 class="text-h2-pre deep-blue--text pb-5 text-left">
                My Profile
              </h2>
              <div class="d-flex flex-column ml-3">
                <div class="d-flex align-start mb-3">
                  <span class="text-data-header">Name:</span>
                  <span class="text-data-body ml-2">
                    {{ data?.firstname }} {{ data?.lastname }}
                  </span>
                </div>
                <div class="d-flex align-start mb-3">
                  <span class="text-data-header">E-mail:</span>
                  <span class="text-data-body ml-2">{{ data?.email }}</span>
                </div>
                <div class="d-flex align-start mb-3">
                  <span class="text-data-header">Phone:</span>
                  <span class="text-data-body ml-2">{{ data?.phone }}</span>
                </div>
                <div class="d-flex align-start mb-3">
                  <span class="text-data-header">Address:</span>
                  <div class="d-flex flex-column ml-2">
                    <span class="text-data-body">{{ data?.address1 }}</span>
                    <span class="text-data-body">{{ data?.address2 }}</span>
                    <span class="text-data-body">
                      {{ data?.city }}, {{ data?.state }} {{ data?.zip }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex flex-column align-start mt-1">
              <span class="text-data-body">
                To update this information, please message your care team.
              </span>
            </div>

            <v-btn
              class="d-flex ml-2"
              @click="openModal()"
              elevation="0"
              style="width: 100%; border-radius: 40px"
              color="primary"
              rounded
            >
              Update Insurance
            </v-btn>
            <UpdateInsuranceDialog
              ref="updateInsurance"
              :dialog="dialog"
              :submitting="submitting"
              @close="dialog = !dialog"
            />
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card>
            <v-card-title class="d-flex align-center justify-space-between">
              <h4>Recent login activities</h4>
            </v-card-title>
            <v-card-text>
              <v-data-table
                :loading="loading"
                :headers="headersSessions"
                :items="sessions"
                :items-per-page="5"
                class="elevation-0"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip top color="error">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon
                          small
                          @click="logoutSpecific(item.id)"
                          color="error"
                        >
                          mdi-logout
                        </v-icon>
                      </span>
                    </template>
                    <span>Logout</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-title class="d-flex align-center justify-space-between">
              <v-btn
                class="d-flex"
                elevation="0"
                color="error"
                @click="logoutAll"
              >
                Logout All
              </v-btn>
              <v-btn
                class="d-flex ml-2"
                elevation="0"
                color="error"
                @click="logoutAllNotCurrent"
              >
                Logout All Not Current
              </v-btn>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="6" v-if="isShowPasswordReset">
          <PasswordReset @reset="reloadPasswordReset" />
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>
<script>
import { auth, api } from '../sharedPlugin';
import axios from 'axios';
import PasswordReset from '../components/passreset/passwordreset.vue';
import UpdateInsuranceDialog from '../components/UpdateInsurance.vue';
import device from '../utils/device';

export default {
  components: {
    PasswordReset,
    UpdateInsuranceDialog
  },
  data() {
    return {
      loading: false,
      isShowPasswordReset: true,
      dialog: false,
      data: [],
      pass1: null,
      pass2: null,
      isFormValid: false,
      message: '',
      showSnackBar: false,
      color: '',
      show1: true,
      rules: {
        required: (value) => !!value || 'Required.',
        min: (v) => v?.length >= 8 || 'Min 8 characters',
        match: this.pass1 === this.pass2 || 'Passwords do not match'
      },
      headersSessions: [
        {
          text: 'Device',
          align: 'start',
          sortable: false,
          value: 'device'
        },
        { text: 'Logged in', sortable: false, value: 'logged_in_at' },
        { text: 'Actions', sortable: false, align: 'end', value: 'actions' }
      ],
      sessions: []
    };
  },
  computed: {
    ...auth.mapComputed(['user']),
    checkMobile() {
      return device() === 'mobile' ? true : false;
    }
  },
  async mounted() {
    await this.getPatientInfo();
    await this.getSessions();
  },
  methods: {
    ...auth.mapMethods(['logout']),
    reloadPasswordReset() {
      this.isShowPasswordReset = false;
      setTimeout(() => {
        this.isShowPasswordReset = true;
      }, 0);
    },
    showMessage({ message, color }) {
      this.color = color;
      this.message = message;
      this.showSnackBar = true;
    },
    async logoutSpecific(id) {
      this.loading = true;
      await api.UserSessions.logout(id)
        .then(() => {
          this.getSessions();
        })
        .finally(() => (this.loading = false));
    },
    async logoutAll() {
      this.loading = true;
      await api.UserSessions.logoutAll()
        .then(() => {
          this.getSessions();
        })
        .finally(() => (this.loading = false));
    },
    async logoutAllNotCurrent() {
      this.loading = true;
      await api.UserSessions.logoutAllNotCurrent()
        .then(async () => {
          await this.getSessions();
        })
        .finally(() => (this.loading = false));
    },
    async getSessions() {
      this.loading = true;
      const { token } = JSON.parse(localStorage.getItem('auth'));
      await api.UserSessions.getSessions()
        .then(({ data }) => {
          const sessions = data.map((session) => {
            session.device = session?.device?.match(/^[^(]+\([^)]*\)/);
            session.logged_in_at = new Date(
              session?.logged_in_at
            ).toLocaleString('en-US', {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric'
            });
            session.current = session.token === token;
            return session;
          });
          this.sessions = sessions;
        })
        .catch(() => {
          this.sessions = [];
          this.showMessage({
            message: 'There was an error getting the sessions',
            color: 'error'
          });
        })
        .finally(() => (this.loading = false));
    },
    async resetPass() {
      await axios
        .put(
          `${process.env.VUE_APP_API_URL}/api/v1/connect/auth/reset`,
          {
            id: this.user.userId,
            password: this.pass1
          },
          {
            headers: {
              'x-api-key':
                '58b54801044df5a0536c25db11c653c743761b2db21e044aedb9f91caa6138a5'
            }
          }
        )
        .then((response) => {
          this.$store.commit('showSnackbar', {
            message: 'Your password has been updated',
            color: 'green'
          });
          this.pass1 = null;
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('showSnackbar', {
            message:
              'There was an error updating your password. Please try again.',
            color: 'red'
          });
        });
    },
    async updatePatient() {},

    openModal() {
      console.log('Open Modal');
      this.$refs.updateInsurance.show();
    },

    async getPatientInfo() {
      return await axios
        .post(
          `${process.env.VUE_APP_API_URL}/api/v1/inova/patients/getConnectPatient`,
          {
            connectId: this.user.userId
          },
          {
            headers: {
              'x-api-key':
                '58b54801044df5a0536c25db11c653c743761b2db21e044aedb9f91caa6138a5'
            }
          }
        )
        .then((response) => {
          this.data = response.data[0];

          this.showMessage({
            message: 'Your profile was updated.',
            color: 'green'
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
};
</script>
<style scoped>
.shape-border {
  border-top-right-radius: 60px;
  border-bottom-left-radius: 60px;
}
.text-data-header {
  font-weight: bold;
  margin-right: 10px;
}
.text-data-body {
  flex: 1;
}
@media (max-width: 600px) {
  .shape-border {
    border-radius: 20px;
  }
  .text-data-header {
    font-size: 14px;
  }
  .text-data-body {
    font-size: 14px;
  }
}
</style>
